




























import {Component, Vue} from 'vue-property-decorator';
import {OutputFmonDaemonRegistrationRequestDTO} from "@/api";
import {formatApiTimeString} from "@/utils";
import {formatRegistrationRequestStatus} from "@/types/FmonDaemonRegistrationRequestStatus";

@Component
export default class RegistrationRequestList extends Vue {
  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Status',
      value: 'status',
      align: 'left'
    },
    {
      text: 'Time',
      value: 'time',
      align: 'left',
    },
    {
      text: 'FMON Daemon UUID',
      value: 'uuid',
      align: 'left'
    },
    {
      text: 'Actions',
      sortable: false,
      align: 'center',
      value: 'actions',
    }
  ];
  public requests: OutputFmonDaemonRegistrationRequestDTO[] = [];

  public async mounted() {
    const data = await this.$store.dispatch('fmonDaemonRegistrationRequests/getAll', { cleared: false });
    this.requests = data ?? [];
  }

  get tableItems() {
    return this.requests.map(r => {
      return {
        status: formatRegistrationRequestStatus(r.status ?? ""),
        id: r.id,
        time: formatApiTimeString(r.created_at),
        uuid: r.fmond_auth_id
      }
    })
  }
}
