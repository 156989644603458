import ApiRole from "@/types/ApiRole";

enum FmonDaemonRegistrationRequestStatus {
    PENDING_BALENA_ID = 'pending-balena-id',
    PENDING_USER = 'pending-user',
    REJECTED_BALENA_ID = 'rejected-balena-id',
    REJECTED_USER = 'rejected-user',
    APPROVED = 'approved',
    PROCESSING_FAILED = 'processing-failed',
    COMPLETED = 'completed'
}

export default FmonDaemonRegistrationRequestStatus;

export function formatRegistrationRequestStatus(status: FmonDaemonRegistrationRequestStatus | string) {
    if (FmonDaemonRegistrationRequestStatus[status]) {
        status = FmonDaemonRegistrationRequestStatus[status];
    }

    switch (status) {
        case FmonDaemonRegistrationRequestStatus.PENDING_BALENA_ID.valueOf():
            return 'Pending Balena Check'
        case FmonDaemonRegistrationRequestStatus.PENDING_USER.valueOf():
            return 'Pending User Approval'
        case FmonDaemonRegistrationRequestStatus.REJECTED_BALENA_ID.valueOf():
            return 'Rejected by Balena Check'
        case FmonDaemonRegistrationRequestStatus.REJECTED_USER.valueOf():
            return 'Rejected by User'
        case FmonDaemonRegistrationRequestStatus.APPROVED.valueOf():
            return 'Approved'
        case FmonDaemonRegistrationRequestStatus.PROCESSING_FAILED.valueOf():
            return 'Approved, Processing Failed'
        case FmonDaemonRegistrationRequestStatus.COMPLETED.valueOf():
            return 'Completed'
    }

    return status
}
